<template>
    <svg id=":contribution" viewBox="0 0 935.43 39.69">
        <g>
            <g>
                <g>
                    <path class="st0" d="M93.83,0.5h18.79l23.5,29.48L135.83,0.5H147v38.78h-18.96l-23.15-29.19l0.29,29.19H93.83V0.5z"/>
                    <path class="st0" d="M157.06,0.5h18.79l23.5,29.48L199.06,0.5h11.17v38.78h-18.96l-23.15-29.19l0.29,29.19h-11.34V0.5z"/>
                    <path class="st0" d="M263.27,0.5v8.78h-31.35v6.69h29.55v7.67h-29.55v6.86h31.76v8.78h-43.39V0.5H263.27z"/>
                    <path class="st0" d="M348.72,10.09h-17.27V0.5h46.36v9.59h-17.27v29.19h-11.81V10.09z"/>
                    <g>
                        <path class="st0" d="M311.43,28c-1.34,1.86-3.43,2.21-13.61,2.21c-8.9,0-10.82-0.23-12.39-1.51c-1.45-1.22-1.92-3.31-1.92-9.01
					c0-5.58,0.46-7.5,2.04-8.72c1.51-1.1,3.9-1.39,12.56-1.39c12.55,0,14.4,0.09,14.67,6.39h11.75c-0.05-2.26-0.15-3.88-0.3-4.88
					c-0.81-6.22-3.72-9.3-9.71-10.41c-2.73-0.47-6.17-0.58-17.33-0.58c-10.24,0-13.79,0.23-17.04,1.16
					c-4.36,1.28-6.63,3.49-7.74,7.62c-0.7,2.62-0.81,4.24-0.81,10.76c0,8.49,0.35,11.34,1.86,14.19c1.45,2.85,3.96,4.48,8.26,5.23
					c2.33,0.46,7.04,0.64,16.29,0.64c3.78,0,8.96-0.06,11.05-0.17c5.64-0.23,8.9-1.16,11.23-3.14c2.91-2.4,4.03-5.61,4.26-12.69
					h-11.82C312.54,25.88,312.16,27.07,311.43,28z"/>
                    </g>
                </g>
                <g>
                    <path class="st0" d="M405.1,0.5l11.28,29.54l10-29.54h14.25l9.71,29.54L461.98,0.5h11.98l-16.05,38.78h-15.82l-8.61-27.91
				l-9.02,27.91h-15.7L393.12,0.5H405.1z"/>
                    <path class="st0" d="M481.47,0.5h11.81v38.78h-11.81V0.5z"/>
                    <path class="st0" d="M518.06,10.09h-17.27V0.5h46.36v9.59h-17.27v29.19h-11.81V10.09z"/>
                    <path class="st0" d="M554.64,0.5h11.81v14.36h26.87V0.5h11.81v38.78h-11.81V24.45h-26.87v14.83h-11.81V0.5z"/>
                    <path class="st0" d="M635.82,26.89c0.12,2.67,0.41,3.31,1.8,3.84c0.87,0.35,4.19,0.58,7.91,0.58c13.67,0,15.3-0.41,15.3-3.6
				c0-1.57-0.76-2.73-1.98-3.14c-1.28-0.41-1.28-0.41-7.39-0.47h-10.7c-7.15,0-10.12-0.41-12.62-1.69c-2.79-1.45-3.1-4.53-3.1-9.83
				c0-6.98,1.01-9.94,6.59-11.28c3.14-0.81,7.68-1.11,16.17-1.11c13.61,0,16.75,0.35,19.54,2.15c2.68,1.8,3.55,4.19,3.55,10h-11.52
				c0-3.37-1.16-3.84-8.78-3.84c-3.78,0-8.84,0.12-10.7,0.23c-3.14,0.23-4.19,1.05-4.19,3.26c0,1.63,0.81,2.67,2.5,3.08
				c0.7,0.18,0.93,0.23,4.77,0.29h10.99c1.05,0,6.28,0.18,8.26,0.29c7.39,0.35,10.3,3.6,10.3,11.4c0,7.15-2.09,10.29-7.79,11.51
				c-2.91,0.64-8.67,0.99-17.1,0.99c-13.44,0-16.4-0.29-19.83-2.03c-2.38-1.16-2.64-4.13-2.64-8.72c0-0.35,0-1.11,0.06-1.92H635.82z
				"/>
                    <path class="st0" d="M695.97,10.09h-17.27V0.5h46.36v9.59h-17.27v29.19h-11.81V10.09z"/>
                    <path class="st0" d="M743.83,0.5h15.82l21.23,38.78h-12.91l-3.84-6.92h-25.01l-3.72,6.92h-13.38L743.83,0.5z M760,24.28
				l-8.38-15.47l-8.26,15.47H760z"/>
                    <path class="st0" d="M797.41,0.5v16.86v2.33c0,4.24,0,4.36,0.18,5.41c0.58,4.36,2.5,5.23,11.34,5.23
				c8.84,0,11.28-0.23,12.74-1.34c1.16-0.87,1.74-2.56,1.8-5.52c0.06-0.87,0.06-0.87,0.06-5.17V0.5h11.81v20.35
				c0.12,9.59-0.7,12.44-4.13,15.52c-2.79,2.15-6.28,3.08-11.34,3.14c-1.8,0.06-2.85,0.06-5.18,0.06l-4.42,0.06
				c-10.93-0.06-13.09-0.17-15.94-0.93c-7.04-2.15-8.9-5.81-8.72-17.33V0.5H797.41z"/>
                    <path class="st0" d="M845.1,0.5h41.18v8.78h-29.43v7.73h27.92v8.78h-27.92v13.49H845.1V0.5z"/>
                    <path class="st0" d="M894.25,0.5h41.18v8.78H906v7.73h27.92v8.78H906v13.49h-11.75V0.5z"/>
                </g>
            </g>
            <g>
                <path class="st0" d="M41.35,28.74c-1.45,2.03-3.63,2.56-11.07,2.66c-1.16,0.02-2.46,0.02-3.92,0.02c-9.65,0-11.68-0.27-13.56-1.8
			c-1.82-1.53-2.38-3.91-2.38-10.19c0-5.49,0.36-8.23,2.51-9.9c1.86-1.36,4.42-1.67,13.73-1.67c1.04,0,2,0,2.9,0.01
			c0.14-0.51,0.3-1,0.48-1.47c0.05-0.13,0.11-0.25,0.17-0.38c0.14-0.34,0.29-0.67,0.45-0.99c0.08-0.16,0.17-0.3,0.26-0.45
			c0.16-0.27,0.32-0.53,0.5-0.78c0.1-0.14,0.21-0.29,0.31-0.42c0.19-0.24,0.39-0.47,0.6-0.69c0.11-0.12,0.22-0.24,0.34-0.36
			c0.26-0.24,0.53-0.47,0.82-0.69c0.09-0.07,0.17-0.15,0.26-0.21c0.39-0.28,0.8-0.54,1.24-0.78c0.12-0.06,0.24-0.12,0.36-0.18
			c0.27-0.14,0.53-0.28,0.82-0.4C33.66,0.02,30.32,0,25.69,0C15.05,0,11.45,0.24,8.19,1.18C3.94,2.42,1.88,4.45,0.81,8.41
			C0.12,11.01,0,12.65,0,19.37c0,8.76,0.36,11.57,1.82,14.33c1.4,2.74,3.76,4.21,7.9,4.94c2.32,0.46,7.19,0.65,16.82,0.65
			c4.09,0,9.35-0.06,11.47-0.18c1.16-0.05,2.19-0.12,3.16-0.23c2.31-0.25,4.13-0.69,5.62-1.35c0.89-0.4,1.66-0.88,2.34-1.46
			c1.77-1.46,2.78-3.2,3.37-5.82c0.38-1.68,0.61-3.81,0.71-6.61H42.9C42.71,26.19,42.26,27.58,41.35,28.74z"/>
                <path class="st0" d="M83.59,10.68l0-0.02c-0.79-6.03-3.49-8.92-9.31-10c-2.78-0.47-6.3-0.59-17.9-0.59
			c-6.93,0-10.67,0.11-13.32,0.39l-0.01,0c-1.19,0.13-2.21,0.3-3.2,0.53c-0.33,0.08-0.65,0.16-0.98,0.25
			c-4.24,1.24-6.31,3.27-7.37,7.24c-0.06,0.22-0.11,0.43-0.16,0.64c-0.43,1.76-0.58,3.34-0.63,6.79h10.5
			c0.23-3.43,0.9-5.13,2.41-6.31c1.63-1.19,3.87-1.55,10.04-1.65c1.1-0.02,2.31-0.02,3.69-0.02c15.21,0,16.36,0.26,16.36,11.32
			c0,5.14-0.24,7.74-1.68,9.55c-1.74,2.42-4.58,2.69-14.99,2.69c-1.01,0-1.92,0-2.76-0.01c-0.01,0.05-0.03,0.1-0.05,0.15
			c-0.04,0.15-0.09,0.29-0.13,0.44c-0.06,0.19-0.12,0.38-0.19,0.56c-0.05,0.14-0.1,0.28-0.16,0.41c-0.07,0.18-0.14,0.35-0.22,0.52
			c-0.06,0.13-0.12,0.26-0.18,0.38c-0.08,0.16-0.17,0.32-0.25,0.48c-0.07,0.12-0.13,0.24-0.2,0.35c-0.09,0.16-0.19,0.31-0.3,0.46
			c-0.07,0.11-0.15,0.22-0.22,0.32c-0.11,0.15-0.23,0.3-0.35,0.44c-0.08,0.1-0.15,0.19-0.23,0.29c-0.14,0.16-0.28,0.31-0.43,0.46
			c-0.07,0.08-0.14,0.15-0.22,0.23c-0.23,0.22-0.47,0.43-0.72,0.64c-0.34,0.29-0.7,0.55-1.08,0.8c-0.1,0.07-0.21,0.14-0.32,0.2
			c-0.33,0.21-0.68,0.4-1.05,0.58c-0.05,0.02-0.09,0.05-0.14,0.08c2.45,0.07,5.53,0.09,9.38,0.09c4.09,0,9.35-0.06,11.47-0.18
			c5.63-0.23,8.85-1.11,11.11-3.04c3.17-2.61,4.13-6.3,4.13-15.79C83.94,15.52,83.82,12.27,83.59,10.68z"/>
            </g>
        </g>

    </svg>
</template>

<script>
    export default {
        name: "Success"
    }
</script>

<style scoped>

</style>
