<template>
    <div>
        <div class="container bg-stauffgreen-seethrough p-10 w-full xl:w-1/2">
            <div class="text-white">
                <h1 class="font-extrabold text-3xl">Welcome to STAUFF.</h1>
                <p class="text-xl mb-8">Please select your country.</p>
            </div>
            <div class="flex flex-row flex-wrap justify-start w-full">
                <AutoSuggest class="hidden md:inline w-full md:w-1/2" :init-value="selectedCountry" :suggestions="countryKeys" :user-selection.sync="selectedCountry" />
                <select v-model="selectedCountry"
                        class="w-full h-10 md:hidden p-2 bg-white border-2 border-black">
                    <template v-for="(value, key) in countryList">
                        <option v-bind:key="key" :value="key">{{key}}</option>
                    </template>
                </select>
                <button @click="changePage" class=" px-3 py-2 bg-black text-white text-xs mt-3 md:mt-0 md:ml-3">
                    <div class="flex flex-inline">
                        <div class="self-center">Go to Website</div>
                        <ArrowForward class="ml-4"/>
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import ArrowForward from './ArrowForward.vue'
    import AutoSuggest from './AutoSuggestField.vue'

    export default {
        name: 'LanguageSwitch',
        props: {},
        created() {
            this.queryParam = this.getUriParams('noredirect');
            this.getCookie();
        },
        computed: {
          countryKeys() {
              return Object.keys(this.countryList);
          }
        },
        methods: {
            getCookie() {
                let $cookie = this.$cookies.get('SelectedCountry');
                if ($cookie && this.countryList[$cookie] && this.queryParam === null) {
                    this.selectedCountry = $cookie;
                }
            },
            setCookie() {
                this.$cookies.set('SelectedCountry', this.selectedCountry);
            },
            changePage() {
                this.setCookie();
                this.redirect();
            },
            redirect() {
                this.$gtm.trackEvent({
                    event: 'StoreSelector',
                    category: 'StoreSelector',
                    action: 'User changed to: ' + this.selectedCountry,
                    label: 'User changed Store',
                    value: 5000,
                });
                if(this.selectedCountry === undefined) {
                    window.location.href = this.countryList['Germany'];
                }
                window.location.href = this.countryList[this.selectedCountry];
            },
            getUriParams($name) {
                let url_string = window.location.href;
                let url = new URL(url_string);
                return url.searchParams.get($name);
            }
        },
        components: {
            ArrowForward,
            AutoSuggest
        },
        data: function () {
            return {
                queryParam: null,
                selectedCountry: 'Germany',
                countryList: JSON.parse(document.getElementById("countryList").getAttribute("data-country-list"))
            }
        }
    }
</script>

<style lang="scss">

</style>


