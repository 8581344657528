import Vue from 'vue'
import App from './App.vue'
import VueGtm from 'vue-gtm'
import './assets/tailwind.css'
Vue.use(require('vue-cookies'))
Vue.use(VueGtm, {
  id: 'GTM-WXNGJHQ',
  enabled: true,
  loadScript: true
});

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
