<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="#FFF" fill-rule="evenodd" d="M12 4L10.59 5.41 16.17 11 4 11 4 13 16.17 13 10.59 18.59 12 20 20 12z"/>
    </svg>
</template>

<script>
    export default {
        name: "ArrowForward"
    }
</script>

<style scoped>

</style>
