<template>
  <div id="app" class="app h-full flex flex-col	">
    <div class="text-center hidden md:block bg-stauffgrey md:px-24 px-5 py-3 text-xs text-gray-600">
      <p class="inline-block pr-2">Original STAUFF Fluidpower Components, Systems and Services</p>
      <p class="inline-block pr-2 keyinfos">Complete Product Range</p>
      <p class="inline-block pr-2 keyinfos">Consistently High Quality</p>
      <p class="inline-block keyinfos">Global Availability and Support</p>
    </div>
    <div class="md:px-24 px-5 py-8 bg-white">
    <Logo />
    </div>
    <div class="stauff-background bg-cover flex-grow md:pt-32 md:pb-40 md:px-24">
      <LanguageSwitch class=""/>
    </div>

    <div class="w-full bg-stauffgreen-lighter pt-5">
      <div class="md:px-24 pb-5 px-6 flex justify-between items-center">
        <a class="text-white underline" href="https://www.stauff.com/en/legal-notice">Legal Notice</a>
        <p class="text-white  text-xs">© {{ new Date().getFullYear() }} STAUFF</p>
        <a class="text-white underline" href="https://www.stauff.com/en/privacy">Privacy</a>
      </div>
      <div class="bg-shadow">
        <Success class="mx-auto py-5 w-1/2 md:w-1/3 xl:w-1/5 text-white" fill="white"/>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSwitch from './components/LanguageSwitch.vue'
import Logo from './components/Logo.vue'
import Success from './components/Success.vue'

export default {
  name: 'App',
  data: function () {
    return {}
  },
  components: {
    LanguageSwitch,
    Logo,
    Success
  },
  mounted() {
    this.$gtm.trackView('StoreSelectorPage', 'https://store-selector.stauff.com/');
  }
}
</script>

<style>
  .keyinfos:before{
      content: "|";
      padding-right: 5px;
   }

  .stauff-background {
    background: url('./assets/Stauff-Neuenrade-Kuentrop-Retusche.jpg') no-repeat center center fixed;
  }

</style>
