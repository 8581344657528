<template>
    <div style="position:relative" v-bind:class="{'open':openSuggestion}">
        <input class="form-control h-10 w-full " type="text" v-model="selection" :placeholder="initValue || 'Choose your Country'"
               @keydown.enter = 'enter'
               @keydown.down = 'down'
               @keydown.up = 'up'
               @input = 'change'
               @focus="(matches.length > 2) ? open = true : open=false"

        />
        <ul class="dropdown-menu absolute ml-3 p-2 overflow-y-scroll bg-white w-64 mt-1" v-if="open">
            <li v-for="(suggestion, index) in matches"
                class="p-2"
                :key="index"
                v-bind:class="{'active': isActive(index)}"
                @click="suggestionClick(index)"
            >
                <a href="#">{{ suggestion }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "AutoSuggestField",
        props: {
            suggestions: {
                type: Array,
                required: true
            },
            initValue: {
                type: String
            }
        },
        data: function (){
            return {
                open: false,
                current: 0,
                selection: ''
            }
        },
        computed: {
            //Filtering the suggestion based on the input
            matches() {
                let result =  this.suggestions.filter((str) => {
                    return str.toLowerCase().indexOf(this.selection.toLowerCase()) >= 0;
                });
                return result;
            },

            //The flag
            openSuggestion() {
                return this.selection !== "" &&
                    this.matches.length !== 0 &&
                    this.open === true;
            }
        },
        methods: {
            //When enter pressed on the input
            enter() {
                this.selection = this.matches[this.current-1];
                this.$emit('update:userSelection', this.selection);
                this.open = false;
            },

            //When up pressed while suggestions are open
            up() {
                if(this.current > 0)
                    this.current--;
            },

            //When up pressed while suggestions are open
            down() {
                if(this.current < this.suggestions.length - 1)
                    this.current++;
            },

            //For highlighting element
            isActive(index) {
                return index === this.current-1;
            },

            //When the user changes input
            change() {
                if (this.open === false) {
                    this.open = true;

                }
                if(this.matches.length === 0) {
                    this.open = false;
                }
                this.current = 0;
            },

            //When one of the suggestion is clicked
            suggestionClick(index) {
                this.selection = this.matches[index];
                this.$emit('update:userSelection', this.selection);
                this.open = false;
            },
        }
    }
</script>

<style lang="scss">

</style>
